<template>
<div class="tabulation"  v-loading="loading" element-loading-text="加载中" element-loading-background="rgb(255, 255, 255, 0.5)">
  <el-card class="chart-card">
    <div style="margin-top: 20px;">
      <div v-for="(item,index) in list" :key="index">
        <!-- 单选 -->
        <div class="choice" v-show="item.type==1">
          <!-- 题目 -->
          <div class="subjects"><span>*</span> {{item.name }}</div>
          <!-- 选项 -->
          <div v-for="(it,index) in item.option" :key="index">
            <el-radio v-model="pitch" :label='it.isSelect' :disabled="it.isSelect==2" size="large">{{it.name}}</el-radio>
          </div>
        </div>

        <!-- 多选 -->
        <div class="choice" v-show="item.type==2">
          <!-- 题目 -->
          <div class="subjects"><span>*</span> {{item.name }}</div>
          <!-- 选项 -->
          <div v-for="(it,index) in item.option" :key="index">
            <el-checkbox v-model="tiems" :true-label="it.isSelect" @change='change' :disabled="it.isSelect==2" size="large">{{it.name}}</el-checkbox>
          </div>
        </div>

        <!-- 简答 -->
        <div class="choice" v-show="item.type==3">
          <!-- 题目 -->
          <div class="subjects"><span>*</span> {{item.name }}</div>
          <!-- 内容 -->
          <div v-for="(it,index) in item.option" :key="index" class="content">
            <el-input type="textarea" disabled v-model="it.content"></el-input>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, onMounted } from 'vue'
import { DTrecord } from '../../utils/api'
import qs from 'qs'
export default{
  name:"substance",
  setup(){
    // 复选框点击
    let change=(value)=>{
      console.log(value)
      value=1
    }
    // 加载中
    let loading=ref(true)
    // 多选框
    let tiems=ref(1)
    // 单选框选中
    let pitch=ref(1)
    // 获取答题列表
    let list =ref([])
    // 会员答题内容
    let matter = ()=>{
      let data ={
        id:sessionStorage.getItem('summation'),
        userId:sessionStorage.getItem('DTuserid')
      }
      DTrecord(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          loading.value=false
          list.value=res.data.data.quesAnswerUsersInfo
        }
      })
    }
    // 按钮加载
    const ConfirmLoading=ref(false)

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      matter()
    })
 
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
    })
 
    return{
      change,
      // 加载中
      loading,
      tiems,
      // 单选框默认选中
      pitch,
      // 答题列表
      list,
      // 按钮加载
      ConfirmLoading,
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .el-textarea.is-disabled .el-textarea__inner{
  color: #000;
}
::v-deep .el-textarea__inner{
  height: 150px;
}
.content{
  width: 40%;
  height: 50%;
  margin-left: 1%;
}
::v-deep .el-checkbox:last-of-type{
  margin-left: 1%;
}
::v-deep .el-radio:last-child{
  margin-left: 1%;
}
.choice{
  margin-bottom: 10px;
}
::v-deep .subjects{
  margin-left: 3px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  color: var(--el-text-color-regular);
  span{
    color: var(--el-color-danger);
  }
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
.subject{
  margin-left: 3px;
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--el-text-color-regular);
  span{
    color: var(--el-color-danger);
  }
}
.el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
.el-form-item__content{
  align-items: flex-end;
}
.referrals{
  color: #cccccc;
  margin-left: 5px;
}
.styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
.styC .el-upload--picture-card{
  display:none;   
}
.el-form-item{
  align-items: center;
}
.aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
.el-card.is-always-shadow{
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.tabulation {
  // margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 80px !important;
  font-weight: bold;
}
</style>
